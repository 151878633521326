const Footer = () => {
  return (
    <footer className="w-full px-3 py-4 mt-10 sm:px-10 xl:px-24 2xl:px-48">
      <div className="w-full h-px mb-2 bg-gray-100" />

      <h3 className="-mb-2 text-xl font-bold">PhoneHub,</h3>
      <h3 className="text-xl font-bold">Elevate Your Mobile Experience.</h3>
    </footer>
  );
};

export default Footer;
